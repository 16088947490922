.carousel-indicators [data-bs-target] {
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin: 7px;
  opacity: 1;
}

.carousel-indicators .active {
  background-color: #ff2851;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  width: 4rem;
  height: 4rem;
  filter: invert(1);
}

.defaultBannerImg {
  width: 100%;
}

.carousel-control-prev {
  background: linear-gradient(to left, #ffffff00 0%, #000000be 100%);
}

.carousel-control-next {
  background: linear-gradient(to left, #000000be 0%, #ffffff00 100%);
}

a.carousel-control-prev:hover {
  opacity: 0.4;
}

a.carousel-control-next:hover {
  opacity: 0.4;
}

.carousel-caption {
  top: 3rem;
  right: 11rem;
  bottom: unset;
  left: unset;
}

.NoEventShowSec {
  position: absolute;
  right: 25rem;
  width: 370px;
}

.loadingDiv {
  text-align: center;
  vertical-align: middle;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  img.LoadingImage {
    width: 15%;
  }
}

.Slider {
  .NewShowSec {
    .NewShowDetail {
      background-color: #e6e6e6;
      padding: 25px;
      padding-bottom: 10px;

      .NewShowHead {
        display: flex;
        justify-content: space-between;

        .NewShowHeading {
          display: flex;
          margin-bottom: 15px;
          align-items: flex-start;

          h5 {
            background-color: #ff2851;
            padding: 9px 25px;
          }
        }

        .NewShowDate {
          text-align: center;

          h5 {
            font-size: 18px;
            margin-bottom: 0;
            color: #4a4a4a;
            text-transform: uppercase;
          }

          h6 {
            font-size: 24px;
            color: #4cb2dc;
            font-weight: bold;
          }
        }
      }

      .NewShowTitle {
        text-align: left;
        text-transform: uppercase;

        h6 {
          font-size: 18px;
          font-weight: bold;
          color: #000;
        }

        h2 {
          color: #ff2851;
          font-size: 40px;
          font-weight: bold;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 320px;
        }
      }

      .NewShowLocation {
        text-align: left;

        p {
          color: #4a4a4a;
          margin-bottom: -1px;

          span {
            color: #000;
            margin-right: 5px;
          }
        }
      }
    }

    .GreyImageSec {
      margin-top: -2px;

      img {
        width: 100%;
      }
    }

    .SkyImageSec {
      margin: -1px 0;
      img {
        width: 100%;
      }
    }

    .NewShowBuy {
      background-color: #4cb2dc;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 10px;
      padding-bottom: 20px;

      button {
        border: 0;
        font-size: 20px;
        font-weight: bold;
      }
    }
  }

  .NoEventShowSec {
    .NewShowDetail {
      opacity: 10;

      .NewShowHead {
        display: flex;
        justify-content: space-between;
        width: 370px;

        .NewShowHeading {
          display: flex;
          align-items: flex-start;
          width: 100% !important;

          .bannerTitle {
            font-size: 32px;
            font-weight: 600;
            background-color: rgb(255 40 81 / 73%);
            padding: 30px 25px;
            width: 100% !important;
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .NewShowDate {
          text-align: center;

          h5 {
            font-size: 18px;
            margin-bottom: 0;
            color: white;
            text-transform: uppercase;
          }

          h6 {
            font-size: 24px;
            color: #4cb2dc;
            font-weight: bold;
          }
        }
      }

      .NewShowTitle {
        text-align: center;
        text-transform: uppercase;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        background-color: rgb(0 0 0 / 74%);
        width: 300px;
        margin: auto;
        // position: relative;
        // left: 2rem;
        // top: -10px;

        h6 {
          font-size: 18px;
          font-weight: bold;
          color: white;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        h2 {
          color: #ff2851;
          font-size: 40px;
          font-weight: bold;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 320px;
        }
      }

      .NewShowLocation {
        text-align: left;

        p {
          color: #4a4a4a;
          margin-bottom: -1px;

          span {
            color: #000;
            margin-right: 5px;
          }
        }
      }
    }

    .GreyImageSec {
      margin-top: -1px;

      img {
        width: 100%;
      }
    }

    .SkyImageSec {
      margin: -1px 0;
    }

    .NewShowBuy {
      margin-top: 10px !important;
      background-color: #4cb2dc;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 10px;
      padding-bottom: 10px;
      width: max-content;
      margin: auto;
      // position: relative;
      // left: 2rem;

      button {
        border: 0;
        font-size: 18px;
        font-weight: bold;
        width: 180px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}

// NoEventShowSec

.EventChild {
  .VenueCompanyDetails-Section .VenueCompanyImg img {
    padding: 10px 0 !important;
  }

  .EventDirectionHeading {
    h3 {
      color: #000 !important;
      font-weight: 800 !important;
    }

    h5 {
      font-size: 14px;
      color: #000;
      font-weight: 700;
    }

    p {
      font-size: 14px !important;
    }

    h4 {
      font-size: 18px;
      font-weight: 800;
      color: #000;
    }
  }

  img.EventSettingImg {
    width: 100% !important;
    height: auto !important;
    cursor: pointer;
  }

  .ReserveSeating {
    text-align: center;
    cursor: pointer;

    .redTopImg {
      width: 85%;
    }

    .preSaleContent {
      h3 {
        margin-bottom: 0;
      }

      .preSaleHeading {
        color: #000;

        span:first-child {
          font-weight: 600;
        }
      }
    }

    .ReservedSec {
      margin-bottom: 0;
      border-radius: 8px;
      padding-top: 15px;
      padding-bottom: 5px;

      h3 {
        font-size: 24px;
        color: #000;
        text-transform: uppercase;
      }

      h4.Read_more {
        font-size: 14px;
        font-family: "Lato";
        font-weight: 700;
        margin: 0;
      }
      p.ModalElipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 22px;
      }
      p.ModalElipsis p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      p {
        font-size: 14px;
      }
    }

    .BgRedBottom {
      width: 100%;
      object-fit: cover;
      margin-bottom: 30px;

      .EventPriceDiv {
        position: absolute;
        top: 0;
        width: 100%;

        h5.PriceClass {
          margin-top: 5px;
        }

        .PriceClass {
          color: #fff;
          font-family: "Roboto Condensed";
          font-size: 24px;
          font-weight: bold;
          margin: 0;
        }

        h3 {
          position: relative;

          top: 10px;
          color: #fff;
          font-family: Lato;
          font-size: 14px;
          font-weight: bold;
        }

        p {
        }
      }

      img.redBottomImg {
        width: 85%;
        height: 65px;
      }
    }
  }

  .EventChildBox {
    text-align: center;

    p.BuyNowHeading {
      color: #ff2851;
      font-weight: 700;
      font-size: 18px !important;
    }

    p.ShowHeading {
      font-size: 13px !important;
      margin-bottom: 5px;
    }

    .ShowLink {
      margin-top: 10px;
      font-size: 12px;
      color: #4cb2dc;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .EventChild-Details-Sec a {
    color: #4cb2dc;

    &:hover {
      color: #248db8;
      text-decoration: underline;
    }
  }

  .bannerTime {
    position: absolute;
    top: 16px;
    left: 12px;
    background-color: #ff2851;
    color: #fff;
    font-weight: 400;
    padding: 2px 15px;
    font-size: 18px;

    p {
      font-size: 24px !important;
      font-weight: 800;
      color: #fff !important;
      margin: 0;
      margin-top: -5px;
    }
  }
}

/* Extra small devices (phones, 600px and down) */
@media (min-width: 320px) and (max-width: 450px) {
  .Slider {
    .NewShowSec {
      margin: 0 20px;
      .NewShowBuy{
        padding: 5px;
        padding-bottom: 15px;
        button{
          padding: 0;
        }
      }
  }
    .carousel-item {
      .carousel-caption {
        width: 100%;
        right: 0rem !important;

        .GreyImageSec {
          margin: -4px 0;

          img {
            width: 100%;
          }
        }

        .SkyImageSec {
          margin: -2px 0;

          img {
            width: 100%;
          }
        }
      }

      .NoEventShowSec {
        position: absolute;
        right: 0 !important;
        bottom: unset;
        left: 0;
        width: -webkit-fill-available;

        .NewShowHead {
          margin: auto;
        }

        .NewShowBuy {
          left: 0;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .Slider {
    .carousel-item {
      img.w-100 {
        width: 100% !important;
        min-height: 400px;
        object-fit: cover;
      }

      .carousel-caption {
        top: 1rem;
        right: 5rem;
        bottom: unset;
        left: unset;
      }

      .NoEventShowSec {
        position: absolute;
        right: 0rem;
      }
    }
  }

  img.LoadingImage {
    width: 80% !important;
  }

  .EventChild {
    .bannerTime {
      font-size: 14px;

      p {
        font-size: 18px !important;
      }
    }
  }

  .EventChild .ReserveSeating {
    .BgRedBottom {
      position: relative;

      img.redBottomImg {
        width: 85%;
        height: 85px;
      }
    }
  }
}

@media (min-width: 539px) and (max-width: 541px) {
  .redTopImg {
    width: 63% !important;
  }
}

@media (min-width: 765px) and (max-width: 780px) {
  .Slider {
    .carousel-item {
      .NoEventShowSec {
        right: 7rem !important;
        top: 6rem !important;
      }
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 768px) and (max-width: 992px) {
  .Slider {
    .carousel-item {
      img.w-100 {
        width: 100% !important;
        min-height: 400px;
        object-fit: cover;
      }

      .carousel-caption {
        top: 1rem;
        right: 5rem;
        bottom: unset;
        left: unset;
      }

      .NoEventShowSec {
        position: absolute;
        right: 9rem;
        left: unset;
        top: 5rem;
      }
    }
  }

  img.LoadingImage {
    width: 70% !important;
  }

  .EventChild .ReserveSeating {
    .BgRedBottom {
      position: relative;

      img.redBottomImg {
        width: 85%;
        height: 75px;
      }
    }
  }
}

@media (min-width: 910px) and (max-width: 915px) {
  .Slider {
    .carousel-item {
      .NoEventShowSec {
        right: 12rem !important;
      }
    }
  }
}

@media (min-width: 1020px) and (max-width: 1028px) {
  .Slider {
    .carousel-item {
      .NoEventShowSec {
        right: 12.5rem !important;
      }
    }
  }
}

@media (min-width: 912px) and (max-width: 1050px) {
  .EventChild-Details-Sec {
    margin-bottom: 18.5rem;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 992px) and (max-width: 1200px) {
  .Slider {
    .carousel-item {
      img.w-100 {
        width: 100% !important;
        min-height: 400px;
        object-fit: cover;
      }

      .carousel-caption {
        top: 1rem;
        right: 8rem;
        bottom: unset;
        left: unset;
      }

      .NoEventShowSec {
        position: absolute;
        right: 13rem;
      }
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media (min-width: 1200px) and (max-width: 1400px) {
  .Slider {
    .carousel-item {
      img.w-100 {
        width: 100% !important;
        min-height: 400px;
        object-fit: cover;
      }

      .carousel-caption {
        top: 1rem;
        right: 9rem;
        bottom: unset;
        left: unset;
      }

      .NoEventShowSec {
        right: 19.5rem;
        top: 4rem;
      }
    }
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media (min-width: 1200px) {
  .EventChild .ReserveSeating {
    .BgRedBottom {
      position: relative;

      img.redBottomImg {
        width: 85%;
        height: 85px;
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .EventChild .ReserveSeating {
    .BgRedBottom {
      position: relative;

      img.redBottomImg {
        width: 85%;
        height: 85px;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .EventChild .ReserveSeating .blueTop {
    width: 85%;
    position: relative;
    top: 5px;
  }
}

.mediaSec {
  .mediaContent {
    img {
      width: 14px;
      object-fit: cover;
    }

    a {
      color: #4cb2dc;
      font-size: 14px;
      margin-left: 6px;
      text-decoration: underline;

      &:hover {
        color: #168cbb;
      }
    }
  }
}

.galleryContent {
  img {
    width: 90px;
    height: 90px;
    object-fit: cover;
    margin-right: 20px;
    margin-bottom: 10px;
    cursor: pointer;
  }
}

// .NoEventBanner {
//   display: flex;
//   width: 100%;
//   justify-content: center;
//   align-items: center;
//   a:first-child {
//     width: 100%;
//   }
// }
