.SeasonTicketsSection {
  .SeasonTicketDetails {
    margin-top: 30px;
    padding: 30px 70px;
    img {
      width: 100% !important;
    }

    a {
      color: #4cb2dc;
    }

    h5 {
      text-align: start !important;
      font-size: 14px;
      font-weight: bold;
    }

    h2 {
      font-size: 31px;
      font-weight: bold;
      color: #000;
      margin: 20px 0;
    }

    h3 {
      font-weight: bold;
      color: #000;
      margin-bottom: 5px;
    }

    p {
      color: #4a4a4a;
    }

    h4 {
      font-weight: bold;
      font-size: 18px;
    }

    h2.ReduceMarginBottom {
      margin-bottom: 25px !important;
    }

    .ReduceMargin {
      margin-top: 0px !important;
    }
  }
}

/* Extra small devices (phones, 600px and down) */
@media (max-width: 768px) {
  .hiring {
    margin-bottom: 15rem;
  }
  .SeasonTicketsSection {
    .SeasonTicketDetails {
      img {
        width: 100%;
      }

      margin-top: 20px;

      .SeasonImageSec img {
        margin-bottom: 0;
      }

      h2 {
        margin: 30px 0;
      }

      margin-top: 40px;

      p {
        margin-bottom: 0.5rem;
      }
    }
  }

  .SeasonFooter {
    margin-top: 60px;
    margin-bottom: 20px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 768px) and (max-width: 992px) {
  .SeasonTicketDetails {
    img {
      width: 100%;
    }
  }
  .hiring {
    margin-bottom: 25rem;
  }
}

@media (min-width: 820px) and (max-width: 1025px) {
  .hiring {
    margin-bottom: 48rem;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 992px) and (max-width: 1200px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media (min-width: 1200px) and (max-width: 1400px) {
  .hiring {
    margin-bottom: 14rem;
  }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .SeasonTicketDetails {
    margin-bottom: 10rem !important;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media (min-width: 1400px) {
  .hiring {
    margin-bottom: 14rem;
  }
}
