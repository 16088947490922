.GuestSection {
  .GuestDetails {
    margin-top: 25px;

    .QuestionSection {
      h3:hover {
        text-decoration: none !important;
      }
      form.GuestForm {
        display: flex;
        align-items: center;
        width: 100%;
        position: relative;
        .GuestSearchInput {
          width: -webkit-fill-available;
          .css-t3ipsp-control {
            box-shadow: none;
            border-color: #c5c5c5;
          }
          span.css-1u9des2-indicatorSeparator {
            display: none;
          }
          div {
            padding-left: 10px;
            border-radius: 4px 0px 0px 4px;
          }
          input {
            padding: 4px !important;
          }
        }
        .SearchIcon {
          position: absolute;
          z-index: 2;
          left: 10px;
          color: #858585;
        }
      }
    }

    .FaqList {
      text-align: center;
    }

    .boxStyle {
      padding: 30px;
      background-color: #fff;
      border: 1px solid #e9e8e8;
      margin-bottom: 30px;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

      a {
        color: #000;
        > &:hover h3 {
          text-decoration: underline;
          text-decoration-color: #4cb2dc;
        }
      }

      img {
        width: 80px;
      }

      h3 {
        margin-top: 25px;
        font-weight: 700;
      }

      .SearchSec {
        .SetPosition {
          position: relative;
          margin: 25px 0;

          .form-outline {
            width: calc(100% - 210px);
          }

          button.SeacrhFaqbtn {
            background-color: #ff2851;
            color: #fff;
            border: 1px solid #e9e8e8;
            height: 44px;
            width: 160px;
            border-left: none;
            font-weight: 500;
            font-size: 17px;
            padding: 9px 20px;
          }

          input#form1 {
            height: 42px;
            border-radius: 0;
            border: 1px solid #e9e8e8;
            margin-top: 1px;
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            padding-left: 35px;
          }
        }
      }

      .GoFaqBtn {
        font-size: 18px;
        line-height: 21px;
        padding: 12px 34px;
        font-weight: 700;
        border: 2px solid #fff;
      }
    }

    .LostSection {
      padding-top: 10px !important;
    }
    .LostSection {
      .LostHeading {
        h3:hover {
          text-decoration: none !important;
        }
        p {
          font-size: 12px;
          margin-bottom: 8px;
          font-family: Lato, "Helvetica Neue", Helvetica, Arial, sans-serif;
          color: #4a4a4a;
        }
      }
      .LostFormSec {
        label {
          font-size: 12px;
          font-weight: 600;
          font-family: "Lato";
        }
        textarea#floatingTextarea2 {
          height: 110px !important;
        }
        .SubmitBtn {
          background-color: #ff2851;
          text-transform: uppercase;
          height: 44px;
          border: none;
          font-size: 18px;
          margin-top: 15px;
          font-weight: 700;
          color: #fff;
          width: 100%;
        }
      }
    }
  }
}

/* Extra small devices (phones, 600px and down) */
@media (max-width: 768px) {
  .GuestSection {
    .input-group {
      display: block;
      width: 100%;

      .form-outline {
        width: calc(100% - 0px) !important;
      }
    }
    .GuestHeading {
      margin-top: 20px;
    }
  }
  .SetPosition button.SeacrhFaqbtn {
    width: 100% !important;
    margin-left: 1px !important;
  }
  .boxStyle {
    padding: 20px !important;
    h3 {
      margin-top: 5px !important;
    }
    .GoFaqBtn {
      width: 100%;
    }
    img {
      margin-bottom: 15px;
    }
  }
  .LostSection {
    margin-top: 20px;
  }
}

@media (min-width: 575px) and (max-width: 768px) {
  .FaqList {
    h3 {
      font-size: 18px;
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 768px) and (max-width: 992px) {
  .VenueShowsCard .VenueCardSection .VenueCard .card {
    min-height: 313px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 992px) and (max-width: 1200px) {
  .VenueShowsCard .VenueCardSection .VenueCard .card {
    min-height: 313px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media (min-width: 1200px) and (max-width: 1400px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media (min-width: 1400px) {
}
