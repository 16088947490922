.FAQSection {
  min-height: 100vh;
  margin-bottom: 5rem;
  .FaqHeadSec {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .FaqPara {
      font-size: 15px;
      span {
        color: #9b9b9b;

        a {
          color: #000;
        }
      }
    }
  }

  .FaqTableSection {
    margin: 35px 0;
    
    .AccordionsSec {
      border-bottom: 2px solid #ff2851;
      padding-bottom: 20px;
      margin-bottom: 30px;
  }

    select.form-select {
      box-shadow: none !important;
    }

    .TableContent {
        position: sticky;
        top: 40px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        padding: 25px;
      h5 {
        font-size: 18px;
      }
    }
  }
}

.feq-title-active {
  color: #ff2851 !important;
}

select {
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
}

option:target {
  background-color: rgb(255, 0, 0) !important;
  box-shadow: none;
}
