@font-face {
  font-family: "Century Gothic";
  //   src: url("/assets/fonts/CenturyGothic/CenturyGothic.ttf") format("truetype");
  //   src: url("../../Assets/gothicfont/centurygothic.ttf") format("woff");
  src: url("../../Assets/gothicfont/centurygothic_bold.ttf") format("woff");
  font-weight: normal;
  font-style: normal;
}

.season2025Section {
  background-image: url("../../Assets/Images/SeasonSeat2025/seasonSeat2025_BG.png");
  font-family: "Century Gothic", Arial, sans-serif;
  color: #fff;
  padding: 40px 29px;
  padding-top: 60px;
  .clrRed {
    color: #e82c2a;
  }
  h1 {
    color: #fff;
    font-size: 54px;
    margin-bottom: 36px;
    font-weight: 700;
  }
  h4 {
    font-size: 36px;
    margin-bottom: 36px;
    font-weight: 700;
  }
  h3 {
    font-size: 47px;
  }
  .seasonBanner {
    text-align: center;
    img {
      margin-bottom: 15px;
    }

    .seasonPackagesHead {
      max-width: 70%;
      margin: auto;
      margin-bottom: 66px;
      a {
        text-decoration: none;
      }
    }
  }
  .seatsDetails {
    margin-bottom: 64px;
    .availableHeading {
      text-align: center;
      h1 {
        margin-bottom: 64px;
      }
    }
    a {
      font-size: 54px;
      margin-bottom: 36px;
      font-weight: 700;
    }
    img {
      width: 100%;
      //   height: 450px;
      object-fit: cover;
    }
    .seasonCardContent {
      text-align: center;
      .forMb {
        margin-bottom: 60px;
      }
      h1 {
        margin-bottom: 0px;
      }
    }
  }
}
.seasonFooter {
  background-color: #c32b29;
  padding: 25px 150px;
  text-align: center;
  h1 {
    font-size: 49px;
    margin-bottom: 0;
    color: #fff;
  }
}
.seatsDetails:last-child {
  margin-bottom: 0;
}
@media (max-width: 768px) {
  .flexColumbReverse {
    flex-direction: column-reverse;
  }
  .season2025Section {
    padding: 10px 0;
    h1 {
      font-size: 34px;
      margin-bottom: 0 !important;
    }
    h3 {
      font-size: 30px;
    }
    h4 {
      font-size: 21px;
      margin-top: 10px;
    }
    .seasonBanner {
      .seasonPackagesHead {
        max-width: 100%;
      }
    }
    .seatsDetails {
      a {
        font-size: 34px;
        margin-bottom: 15px;
        display: block;
      }
    }
    .seasonCardContent {
      .forMb {
        margin-bottom: 20px !important;
      }
    }
  }

  .seasonFooter {
    padding: 25px 10px;
    h1 {
      font-size: 20px;
    }
  }
}
@media (min-width: 768px) and (max-width: 1200px) {
  .season2025Section {
    padding: 25px 10px;
    h1 {
      font-size: 30px;
      margin-bottom: 0 !important;
    }
    h3 {
      font-size: 26px;
    }
    h4 {
      font-size: 20px;
    }
    .seasonBanner {
      .seasonPackagesHead {
        max-width: 90%;
      }
    }
    .seatsDetails {
      a {
        font-size: 30px;
      }
    }
    .seasonCardContent {
      .forMb {
        margin-bottom: 25px !important;
      }
    }
  }
  .seasonFooter {
    padding: 25px 20px;
    h1 {
      font-size: 30px;
    }
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
}

@media (min-width: 1400px) {
  .season2025Section {
    .seasonCardContent {
      h4 {
        line-height: 44px;
        margin: 0;
        padding: 0 10px;
      }
      img {
        max-height: 420px;
      }
    }
  }
}
