.imagePreviewSec {
  .modal-body {
    text-align: center;
    img {
      width: 100%;
      object-fit: cover;
    }
  }
}

.slideBtnSec {
    display: flex;
    width: 100%;
    justify-content: center;
    button {
      border: 1px solid #fff;
      background: transparent;
      outline: none;
      cursor: pointer;
      color: #ffffff;
      background-color: #1f1f1f;
    }
  }