.ContactSection {
    a.LocationNumber {
        font-size: 12px;
        margin: 0;
        margin-bottom: 15px;
    }

    .ContactHeadSec {
        margin-bottom: 35px;
    }

    .ContactFormSec {
        .NameDiv {
            margin-bottom: 24px;
        }

        label.form-label {
            font-weight: 600;
            font-size: 12px;
        }

        textarea#floatingTextarea {
            height: auto;
            resize: none;
            min-height: 174px;
        }
    }

    h3 {
        font-weight: bold;
        color: #000;
        margin-bottom: 15px;
    }

    .SocialMediaSec {

        i.fa-brands {
            color: #ffffff;
            background-color: #4CB2DC;
            border-radius: 50%;
            margin: 7px;
            height: 24px;
            width: 24px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        a {
            display: flex;
            align-items: center;
            text-decoration: underline;
            color: #4CB2DC;

            &:hover {
                color: #4CB2DC;
                text-decoration: underline;
            }
        }
    }

    p.LocationName {
        font-size: 12px;
        font-weight: bold;
        margin: 0;
    }
    .ContactSubmitBtn {
        button {
            background-color: #FF2851;
            color: #fff;
            font-weight: 600;
            font-size: 18px;
            padding: 10px 45px;
            border: 0;
        }
    }
    .LocationEmail {
        color: #4a4a4a;
        font-size: 12px;
    }

    .LocationEmail:hover {
        text-decoration: underline;
    }

    .EmailSec div {
        margin-bottom: 10px;
        font-family: lato;
    }
}