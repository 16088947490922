.privacyWrapper {
  h1 {
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 44px;
  }
  h2 {
    font-size: 32px;
    color: #000;
    font-weight: 600;
    margin-bottom: 20px;
  }
  h3 {
    font-size: 24px;
    color: #000;
    font-weight: 600;
    margin-bottom: 15px;
  }
  h4 {
    font-size: 20px;
    color: #000;
    font-weight: 600;
    margin-bottom: 15px;
    margin-top: 15px;
  }
  p {
    font-size: 16px;
    color: #000;
    margin: 16px 0;
    line-height: 28px;
  }
  li {
    color: #000;
    font-size: 16px;
    margin-bottom: 20px;
  }
  a {
    color: #ff2851;
  }
}
@media (max-width: 768px) {
  .privacyWrapper {
    h1 {
      font-size: 32px;
    }
    h2 {
      font-size: 26px;
    }
    h3 {
      font-size: 22px;
    }
  }
}
@media (min-width: 768px) {
  .privacyWrapper {
    div {
      padding: 15px 0;
    }
  }
}
