footer {
  a {
    text-decoration: none;
    color: #fff;
    font-size: 13px;
    font-family: "Lato";

    &:hover {
      color: #fff;
      text-decoration: underline;
      // text-transform: uppercase;
    }
  }

  .SocialIcons {
    a:hover {
      text-decoration: none !important;
    }
  }

  .FooterSection {
    background-color: #ff2851;
    // margin-top: 50px;

    .FooterDetails {
      color: #fff;
      text-align: center;

      img {
        width: 11%;
        margin: 15px;
      }

      span.DownloadHeading {
        font-size: 21px;
        font-weight: 700;
        font-family: "Roboto Condensed";
      }

      span.FavHeading {
        font-size: 20px;
        padding-left: 5px;
      }
    }
  }

  .bgBlack {
    background-color: black;
    padding: 15px;

    .NavbarSection {
      display: flex;
      justify-content: space-between;
      align-items: center;

      img.HeaderLogoIcon {
        width: 121px;
      }

      ul.pagelinks {
        display: flex;

        a.footer-nav-link.active {
          text-decoration: underline;
        }

        li {
          list-style: none;
          padding: 10px;
        }
      }

      .SocialIcons {
        display: flex;
        align-items: center;
        justify-content: center;

        a {
          margin: 0 3px;

          svg {
            color: black;
            background-color: grey;
            border-radius: 50%;
            height: 16px;
            width: 16px;
            padding: 5px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-weight: 900;
          }
        }
      }
    }
  }

  .LastLine {
    text-align: center;
    background-color: #272727;
    color: #fff;

    p {
      margin: 0;
      padding: 12px;

      a {
        color: #4cb2dc;
        text-decoration: none;
        margin-left: 5px;
      }

      a:hover {
        color: #248db8;
        text-decoration: underline;
      }
    }
  }
}

/* Extra small devices (phones, 600px and down) */
@media (max-width: 768px) {
  .FooterSection {
    span.DownloadHeading {
      display: block;
      text-align: start;
    }

    span.FavHeading {
      text-align: start;
      display: block;
    }

    .FooterDetails {
      padding-top: 10px;

      img {
        width: 28% !important;
        margin: 15px;
      }
    }
  }

  .pagelinks {
    width: auto;
    text-align: center;
    padding: 0 5px 15px;
    line-height: 24px;
    margin-top: 15px;
    display: block !important;

    li {
      list-style-type: none;
      display: inline;
      font-size: 13px;
      padding: 0px !important;

      a {
        color: #fff;
        margin: 13px;
      }
    }
  }

  .bgBlack {
    text-align: center;
    padding-top: 25px !important;
    padding: 10px 0 !important;

    .NavbarSection {
      display: block !important;
    }
  }
  .BRclass {
    display: none;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 768px) and (max-width: 992px) {
  .BRclass {
    display: none;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 992px) and (max-width: 1200px) {
  .BRclass {
    display: block;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media (min-width: 1200px) and (max-width: 1400px) {
  .BRclass {
    display: none;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media (min-width: 1400px) {
  .BRclass {
    display: none;
  }
}
