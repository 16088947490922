.JobSection {
  position: relative;

  .container-fluid {
    padding: 0;

    .JobBG {
      background-image: url(../../Assets/Job_Bg.png);
      padding-top: 150px;
      padding-bottom: 200px;
      background-size: cover;
      background-position: bottom;
      width: 100%;

      .JobHead {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;

        .JobHeadingDiv {
          margin-right: 60px;
        }

        .JobParaDiv {
          p {
            margin-bottom: 0;
          }

          p.GreatHeading {
            color: #FF2851;
          }
        }

        h1.JobsHeading {
          color: #FF2851;
          font-size: 90px;
        }

        .JobParaDiv {
          h3 {
            font-size: 44px;
            font-weight: 700;
            font-family: 'Roboto Condensed';
            color: #fff;
            // text-transform: uppercase;
          }
        }
      }
    }
  }

  .JobDetailSection {
    position: relative;
    top: -120px;


    .HiringSec {
      .SearchSec{
        position: relative;
        input{
          width: 100%;
          padding: 6px;
          padding-left: 30px;
          &:focus-visible{
outline: 0;
          }
        }
        .searchIcon{
          position: absolute;
          top: 12px;
          left: 12px;
        }
      }
      .CategorySelect{
        margin-top: 20px;
          h3 {
        font-family: 'Roboto Condensed';
        font-weight: 700;
        font-size: 24px;
        color: #000000;
      margin-bottom: 10px;
      }
            .categoryRadioBtn{
              max-height: 185px;
              overflow: auto;
        .SelectCategoryDiv{
        margin: 12px 0;
        display: flex;
        align-items: center;
       
        input{
          margin: 0 7px;
        }
        span{
          font-size: 16px;
        }
      }
    }
      }
      

      p {
        font-family: 'Lato';
        font-weight: 400;
        font-size: 16px;
        color: #575353;
        margin: 20px 0;
      }

      .hiringBtn {

        button {
          border: 0;
          padding: 9px 20px;
          font-weight: 600;
          font-size: 16px;
          a{
            text-decoration: none;
            color: #fff;
          }
        }
      }
    }

    .PositionSec {
      h3 {
        font-family: 'Roboto Condensed';
        font-weight: 700;
        font-size: 24px;
        color: #000000;
      }

      p {
        font-family: 'Lato';
        font-weight: 400;
        font-size: 16px;
        color: #575353;
        margin: 15px 0;
      }
      button{
        border: 0;
    padding: 8px 35px;
    font-weight: 600;
    font-size: 16px;
      }

      .PositionContent {
        .CategoryHeading {
          p {
            font-family: 'Lato';
            font-weight: 400;
            font-size: 14px;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: #000000;
          }
        }
      }
    }
    .CategoryPara{
      max-height: 200px;
      overflow: auto;
    }
    .ApplyBtn {
      margin-top: 20px;
  }
  }
 
}

.bg-colour {
background-color: #fff;
}

/* Extra small devices (phones, 600px and down) */
@media (max-width: 768px) {

  .JobSection {
    .container-fluid {
      .JobBG {
        padding-top: 55px;

        .JobHead {
          display: block;
          text-align: center;
          .JobHeadingDiv {
            margin-right: 0;

            h1.JobsHeading {
              font-size: 50px;
            }
          }

          .JobParaDiv {
            h3 {
              font-size: 25px;
            }
          }
        }
      }
    }

    .JobDetailSection {
      .HiringSec {
        h3 {
          font-size: 18px;
        }

        p {
          font-size: 14px;
        }
      }

      .PositionSec {
        h3 {
          font-size: 18px;
        }

        p {
          font-size: 14px;
        }
      }
    }
  }
}


/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 768px) and (max-width: 992px) {
  .JobSection {
    .container-fluid {
      .JobBG {
        padding-top: 100px;
        padding-bottom: 150px;

        .JobHead {
          display: block;
          text-align: center;
          .JobHeadingDiv {
            margin-right: 0;

            h1.JobsHeading {
              font-size: 50px;
            }
          }
        }
      }
   }  
   .JobDetailSection {
    .HiringSec {
      h3 {
        font-size: 22px;
      }

      p {
        font-size: 16px;
      }
    }

    .PositionSec {
      h3 {
        font-size: 22px;
      }

      p {
        font-size: 16px;
      }
    }
  }
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .JobSection {
    .container-fluid {
      .JobBG {
        padding-top: 100px;
        padding-bottom: 150px;

        .JobHead {
          display: block;
          text-align: center;
          .JobHeadingDiv {
            margin-right: 0;

            h1.JobsHeading {
              font-size: 50px;
            }
          }
        }
      }
   }  
  }

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media (min-width: 1400px) {}