.bookingSection {
  h2 {
    font-size: 58px;
    color: #06a0d7;
    font-weight: 500;
  }
  p {
    font-size: 32px;
    color: #000000;
  }
  .bookingHeader {
    text-align: center;
    margin-bottom: 70px;
    img {
      margin-top: 66px;
      margin-bottom: 45px;
    }
    h1 {
      margin-bottom: 41px;
      font-size: 66px;
      font-weight: 600;
      color: #06a0d7;
    }
    h6 {
      color: #000000;
      font-size: 48px;
    }
  }
  .bookNowHead {
    margin-bottom: 80px;
    h5 {
      font-size: 51px;
      text-align: center;
      color: #000;
      a {
        color: #06a0d7;
        text-transform: uppercase;
        font-weight: 500;
        text-decoration: underline;
      }
    }
  }
  .roomsSection {
    margin-bottom: 65px;
    img {
      width: 100%;
    }
  }
  .container-fluid {
    padding: 0;
    overflow: hidden;
  }
  .bookingFooter {
    background-color: #06a0d7;
    padding: 30px 40px;
    h5 {
      font-size: 48px;
      color: #fff;
      text-align: center;
      max-width: 80%;
      margin: auto;
    }
  }
}
@media (max-width: 767px) {
  .bookingSection {
    .forMbFlex {
      .row {
        flex-direction: column-reverse;
      }
    }
  }
}
@media (max-width: 992px) {
  .bookingSection {
    h2 {
      font-size: 26px;
    }
    p {
      font-size: 16px;
    }
    .bookingHeader {
      margin-bottom: 30px;
      img {
        margin-bottom: 30px;
        width: 40%;
      }
      h1 {
        font-size: 40px;
        margin-bottom: 20px;
      }
      h6 {
        font-size: 26px;
      }
    }
    .bookNowHead {
      margin-bottom: 30px;
      h5 {
        font-size: 26px;
      }
    }
    .roomsSection {
      margin-bottom: 35px;
    }
    .bookingFooter {
      padding: 15px;
      h5 {
        font-size: 18px;
        max-width: 100%;
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1280px) {
  .bookingSection {
    h2 {
      font-size: 40px;
    }
    p {
      font-size: 24px;
    }
    .bookingHeader {
      margin-bottom: 50px;

      img {
        width: 25%;
      }
      h1 {
        font-size: 50px;
        margin-bottom: 25px;
      }
      h6 {
        font-size: 38px;
      }
    }
    .bookNowHead {
      margin-bottom: 50px;

      h5 {
        font-size: 40px;
      }
    }
    .bookingFooter {
      padding: 15px;
      h5 {
        font-size: 18px;
        max-width: 100%;
      }
    }
  }
}
