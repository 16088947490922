.App {
  text-align: center;
}
body {
  font-size: 14px;
}
.dataNotFound {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.NoFoundPara {
  margin: 2rem 0 !important;
}

h1 {
  font-weight: 700;
  color: #000;
  font-size: 44px;
  text-transform: uppercase;
}
h3 {
  font-size: 22px;
}
a {
  text-decoration: none;
  color: rgb(175, 175, 175);
}
span.StarColor {
  color: #ff2851;
}
.form-control {
  border-radius: 0;
}
.form-control:focus {
  box-shadow: none;
}
.btn-primary {
  color: #ffffff;
  background-color: #4cb2dc;
  border-color: #4cb2dc;
}
.border-BottomClass {
  border-bottom: 3px solid #000;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.boxStyle {
  padding: 30px;
  background-color: #fff;
  border: 1px solid #e9e8e8;
  margin-bottom: 30px;
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
}
.MainCommon {
  margin-top: 65px;
}

::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(0, 0, 0);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}
.jodit-status-bar {
  display: none !important;
}
input.buttonStyle {
  background-color: #ff2851 !important;
}
button.btn-close:focus {
  box-shadow: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .mobileViewHide {
    display: none !important;
  }
  h1 {
    font-size: 32px;
  }
  .MainCommon {
    margin-top: 30px;
  }
}
@media (min-width: 769px) {
  .HideOnWeb {
    display: none !important;
  }
}
body {
  font-family: "Roboto Condensed", sans-serif;
}
.carousel-indicators {
  bottom: -10px;
}
