.StoreSec {
  .carousel-caption {
    bottom: 3rem;
    left: 8rem;
    top: unset;
    right: 8rem;

    .StoreBanBTN {
      font-size: 25px;
      font-weight: 800;
      background-color: #ff2851;
      color: #fff;
      padding: 10px 50px;
      border: 0;
      border-radius: 11px;
      box-shadow: rgb(0 0 0) 0px 8px 33px, rgba(0, 0, 0, 0.12) 0px 0px 30px,
        rgba(0, 0, 0, 0.12) 0px 4px 4px, rgba(0, 0, 0, 0.17) 0px 12px 13px,
        rgba(0, 0, 0, 0.09) 0px -2px 15px;
    }
  }
}

@media (max-width: 768px) {
  .StoreSec {
    .carousel-caption {
      bottom: 1.5rem;
      left: 2rem;
      right: 2rem;

      .StoreBanBTN {
        font-size: 12px;
        padding: 2px 14px;
        border-radius: 5px;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .StoreSec {
    .carousel-caption {
      bottom: 2rem;
      left: 4rem;
      right: 4rem;

      .StoreBanBTN {
        font-size: 22px;
        padding: 2px 14px;
        border-radius: 5px;
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1280px) {
  .StoreSec {
    .carousel-caption {
      bottom: 2rem;
      left: 4rem;
      right: 4rem;

      .StoreBanBTN {
        font-size: 32px;
        padding: 2px 32px;
        border-radius: 5px;
      }
    }
  }
}

.venueCardSlider .carousel-item {
  aspect-ratio: 16/9;
  .d-block {
    max-width: 100%;
    width: auto !important;
  }
}
.venueCardSlider .active.carousel-item,
.venueCardSlider .carousel-item-prev,
.venueCardSlider .carousel-item-end,
.venueCardSlider .carousel-item-start {
  display: flex;
  align-items: center;
  justify-content: center;
}
