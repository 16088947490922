.PackageSection {
  .container-fluid {
    padding: 0;

    .PackageBG {
      background-image: url(../../Assets/Images/packages.png);
      padding-top: 200px;
      padding-bottom: 50px;
      background-size: cover;
      width: 100%;

      .PackageHead {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h1.PackageHeading {
          color: #ffffff;
          font-family: "Roboto Condensed", sans-serif;
          font-size: 68px;
          font-weight: bold;
          line-height: 80px;
          text-align: center;
          text-transform: capitalize;
          margin-top: 0;
        }

        h3 {
          color: #ff2851;
          font-family: "Roboto Condensed", sans-serif;
          font-size: 25px;
          font-weight: bold;
          line-height: 29px;
          text-align: center;
          text-transform: uppercase;
        }
        .PackageDropdown {
          color: #000000;
          font-family: "Roboto Condensed", sans-serif;
          font-size: 18px;
          font-weight: bold;
          line-height: 21px;
          text-align: center;
          padding: 10px;
          display: inline-block;
          margin-top: 30px;
          padding-right: 35px;
          width: 250px;
          height: 50px;
          border: 0;
          outline: none;
          border-radius: 0;
          box-shadow: none;
        }
      }
    }
  }
  .PackageDetailsSec {
    margin: 40px 0;
    button.PackageBtn {
      padding: 9px 10px;
      font-size: 16px;
      font-weight: 600;
      font-family: "Lato";
      text-transform: uppercase;
      border: 0;
    }
  }
}

.description {
  img {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .PackageSection {
    .container-fluid {
      .PackageBG {
        padding-top: 100px;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .PackageSection {
    .PackageDetailsSec {
      margin-bottom: 29rem !important;
    }
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .PackageDetailsSec {
    margin-bottom: 18rem !important;
  }
}
