.ShowsSection {
  background-color: #f6f6f6;
  padding-bottom: 35px;

  .StoreBannerSec {
    margin: 50px 0;
  }

  .featuredShowSec {
    margin-top: 30px;
  }

  .border-BottomClass {
    border-bottom: 3px solid #000;
    padding-bottom: 10px;
    margin: 10px 2px;
  }

  .featuredContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    margin-top: 30px;
    word-break: break-word;

    .featuredHeading {
      width: 100%;
    }

    .GetUpdateSec {
      button.UpdateSecBtn {
        background-color: #4cb2dc;
        border: 0;
        color: #fff;
        border-radius: 0;
        font-size: 18px;
        font-weight: 700;
        text-transform: uppercase;
        padding: 11px 14px;
        width: 100%;
      }
    }

    .modal-title {
      margin: auto;
    }

    button.btn-close {
      margin: 0;
    }

    .modalBG {
      background-color: #009fd6;
      margin: 12px;
      border-radius: 6px;
      color: #fff;

      .ModalSignupBtn {
        button.btn {
          width: 100%;
          background-color: #fff;
          color: #009fd6;
          border: 0;
          border-radius: 3px;
          padding: 12px 0;
        }
      }
    }

    .modal-footer {
      justify-content: center;
    }

    .SelectSec {
      display: flex;
      align-items: center;
      position: relative;
      width: -webkit-fill-available;
      justify-content: flex-end;

      .GridIcon {
        position: relative;
        top: 4px;
      }

      .form-select:focus {
        box-shadow: none;
      }
    }
  }

  .upcomingShowSec {
    margin-top: 50px;
    display: flex;
    align-items: center;

    i.fa-sharp.fa-solid.fa-list {
      margin: 0 10px;
      font-size: 25px;
      color: #ff2851;
    }
  }

  .locationContent {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    span.LocationField {
      width: 130px;
      margin: 0 5px;

      span.css-1u9des2-indicatorSeparator {
        width: 0;
      }

      div {
        border: 0;
        // background-color: transparent;
        color: #ff2851;
        box-shadow: none;
      }

      input:focus {
        border: none;
      }

      svg {
        width: 15px;
        height: 15px;
      }
    }

    .selectHeading {
      width: auto;
      font-weight: 700;
      font-size: 18px;
    }

    select.form-select {
      border: 0;
      background-color: #f6f6f6;
      color: #ff2851;
      font-weight: 400;
      font-size: 17px;
      color: #ff2851;

      option {
        color: black;
      }
    }
  }
}

.LoadMoreBtn {
  text-align: center;
  margin: 20px 0;

  button.LoadMore {
    font-size: 21px;
    padding: 7px 45px;
    font-weight: 500;
    text-transform: uppercase;
    border: 0;
    margin: 25px 0;
    background-color: #4cb2dc;
    color: #fff;
    border-radius: 0;
  }

  button.disabled {
    display: none;
  }
}

/* Extra small devices (phones, 600px and down) */
@media (max-width: 768px) {
  .ShowsSection {
    margin-top: 30px;

    h1 {
      font-size: 32px;
    }

    .featuredContent {
      display: block;
      word-break: break-word;

      .GetUpdateSec button.btn {
        width: 100%;
        margin-bottom: 7px;
      }

      .SelectSec {
        display: block;

        .GridIcon {
          position: absolute;
          top: 5px;
          display: flex;
          right: 0;

          .gridIconVenue {
            position: relative;
            top: 50px;
            left: -43px;
          }
        }
      }
    }
  }

  .upcomingShowSec {
    margin-top: 40px !important;

    .locationContent {
      justify-content: flex-start;
      margin: 10px 0;
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 768px) and (max-width: 992px) {
  .ShowsSection {
    .upcomingShowSec {
      margin-top: 60px;

      .featuredContent {
        display: block;
        word-break: break-word;

        .SelectSec {
          justify-content: space-between;
          margin-top: 20px;
        }
      }
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 992px) and (max-width: 1200px) {
  .ShowsSection {
    .upcomingShowSec {
      margin-top: 30px;
    }

    .featuredContent {
      .featuredHeading {
        width: auto;
        h1 {
          width: auto;
        }
      }
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media (min-width: 1200px) and (max-width: 1400px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media (min-width: 1400px) {
}

// Slider Css
span.carousel-control-prev-icon.test {
  display: block;
  position: absolute;
  top: 45%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 10;
  filter: brightness(0.5);
}

span.carousel-control-next-icon.tests {
  display: block;
  position: absolute;
  top: 45%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  right: 0;
  filter: brightness(0.5);
  z-index: 10;
}

// span.carousel-control-next-icon {
//   display: none;
// }
// span.carousel-control-prev-icon {
//   display: none;
// }
a.carousel-control-prev,
a.carousel-control-next {
  width: 5%;
  background: none;
}
