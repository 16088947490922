$color-red: #ff2851;
$color-white: #ffffff;

.NavbarSection {
  nav.navbar {
    background-color: #000;
    .ticketDropdown {
      padding: 0;
    }

    ul.navbar-nav {
      margin-right: 40px;
    }

    img.HeaderLogoIcon {
      width: 121px;
    }

    li.nav-item {
      font-weight: 500;
      font-size: 12.5px;
      padding: 0 10px;

      a.nav-link.active {
      }

      a.nav-link {
        color: $color-white;
        padding: 5px 4px;
        font-family: "lato";
        text-transform: uppercase;
      }

      ul.dropdown-menu {
        border-radius: 3px;

        a.dropdown-item {
          font-size: 14px;
        }

        a.dropdown-item:active {
          background-color: #fff;
          color: #000;
        }
      }
    }

    .SocialIcons {
      display: flex;
      align-items: center;
      justify-content: center;

      a {
        text-decoration: none;
        margin: 0 2px;

        svg {
          color: black;
          background-color: grey;
          border-radius: 50%;
          height: 16px;
          width: 16px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 13px;
          font-weight: 900;
          padding: 5px; 
        }
      }
    }
  }
}

/* Extra small devices (phones, 600px and down) */
@media (max-width: 768px) {
  button.navbar-toggler {
    transform: rotate(90deg);
    width: 64px;
  }

  span.icon-bar {
    background-color: white;
    padding: 1px;
    margin: 4px;
  }

  span.navbar-toggler-icon {
    position: relative;
    z-index: -1;
    top: -4px;
    left: -28px;
  }

  .NavbarSection nav.navbar {
    padding: 3px 0;

    img.HeaderLogoIcon {
      padding: 7px 0;
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 768px) and (max-width: 992px) {
  button.navbar-toggler {
    transform: rotate(90deg);
    width: 64px;
  }

  span.icon-bar {
    background-color: white;
    padding: 1px;
    margin: 4px;
  }

  span.navbar-toggler-icon {
    position: relative;
    z-index: -1;
    top: -4px;
    left: -28px;
  }

  .extraHide {
    display: none !important;
  }
}

.nav-item a:hover {
  border-bottom: 3px solid #ff2851;
  padding-bottom: 0 !important;
}
.active .nav-item a {
  border-bottom: 3px solid #ff2851;
  padding-bottom: 0 !important;
}
