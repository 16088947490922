.TableContent {
  h5 {
    font-weight: bold;
  }

  .TableContestList a {
    color: #000;
    display: block;
    padding: 7px 0;
    font-family: lato;
    cursor: pointer;
    font-weight: bold;
  }

  a.active {
    color: #ff2851;
  }
}

.TableContestList {
  margin: 30px 0;

  p {
    font-size: 13px;
    color: #000;
    font-weight: bold;
    font-family: lato;
    cursor: pointer;
  }
}

.faqQue {
  ul {
    margin: 20px 0;

    li {
      &::marker {
        color: #ff2851;
        font-size: 20px;
      }

      a {
        color: #ff2851;
      }
    }
  }
}

/* Extra small devices (phones, 600px and down) */
@media (max-width: 768px) {
  .tabletVeiwHide {
    display: none;
  }

  .WebViewHide {
    display: block;
  }

  .FaqPara {
    text-align: end;
    padding-left: 30px;
  }

  .FaqTableSection {
    button.DropBtn {
      width: 100%;
      background-color: #4cb2dc;
      color: #fff;
      border-radius: 0;
      margin-bottom: 46px;
    }

    button.DropBtn:hover {
      background-color: #2286af !important;
      border: 0;
      color: #fff;
    }

    .btn.DropBtn.dropdown-toggle.show:focus {
      outline: 0;
    }

    .DropUL {
      width: 100%;
      padding: 10px;

      a.feq-title {
        color: #000;
      }
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 768px) and (max-width: 992px) {
  .tabletVeiwHide {
    display: block;
  }

  .WebViewHide {
    display: none;
  }

  .FAQSection {
    .FaqTableSection {
      .TableContent {
        padding: 25px 10px;
      }
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 992px) and (max-width: 1200px) {
  .tabletVeiwHide {
    display: block;
  }

  .WebViewHide {
    display: none;
  }

}

/* Large devices (laptops/desktops, 992px and up) */
@media (min-width: 1200px) and (max-width: 1400px) {
  .tabletVeiwHide {
    display: block;
  }

  .WebViewHide {
    display: none;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media (min-width: 1400px) {
  .tabletVeiwHide {
    display: block;
  }

  .WebViewHide {
    display: none;
  }
}