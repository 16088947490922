.SecondCardSection {
    margin: 10px 0;
    padding: 0;

    .SecondCardContent {
        display: flex;
        justify-content: space-between;
        // box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
        border: 1px solid #E9E8E8;
        padding: 0;
        margin-top: 10px;

        .SeconCardDate {
            background-color: #000;
            color: #fff;
            font-weight: 500;
            font-size: 18px;
            text-align: center;
            width: 46px;
            display: flex;
            align-items: center;
            justify-content: center;

            .date {
                padding: 15px 5px;
                text-transform: uppercase;
            }
        }

        .SecondCardTitle {
            display: flex;
            width: calc(100% - 22%);
            justify-content: space-between;
            align-items: center;

            .RightDiv {
                margin-left: 10px;
                text-transform: uppercase;
            }

            h3 {
                font-weight: 700;
                color: #000;
            }

            p {
                color: #ff2851;
                font-weight: 600;
            }

            .LeftDiv {
                margin-right: 20px;
                text-align: left;
                display: flex;
                align-items: center;

                .SubContent {
                    display: flex;
                }

                a {
                    color: #4cb2dc;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }

            .parkingBtn {
                font-size: 13px;
                line-height: 15px;
                border: 1px solid #fff;
                outline: 3px solid #4cb2dc;
                text-transform: uppercase;
                background-color: #4cb2dc;
                padding: 6px 10px;
                margin: 5px 10px;
                color: #fff;
                font-weight: 700;
                font-family: "Lato";
                width: 110px;
            }
        }

        .SecondCardBuy {
            width: calc(100% - 85%);

            .beforeOnSalebtn {
                height: 100%;

                .SaleHeading {
                    height: 100%;
                }
            }
        }
    }

    button.Price {
        background-color: #4A4A4A;
        color: #fff;
        padding: 8px 0;
        border: 0;
        font-size: 17px;
        font-weight: 600;
        width: 100%;
    }

    .SaleHeading {
        width: 100%;
        background-color: #FF2851;
        border: 0;
        color: #fff;
        font-size: 17px;
        font-weight: 600;
        padding: 8px 0;

        a {
            color: #fff;
            text-decoration: none;

            span {
                font-size: 14px;
            }
        }
    }

    .HideOnWeb {
        display: flex;

        .PriceBtn {
            width: 100%;
        }

        .tbaBtn {
            width: 100%;
        }
    }
}

/* Extra small devices (phones, 600px and down) */
@media (max-width: 768px) {
    .SecondCardSection {
        .SecondCardContent {
            margin-top: 30px;
            border-bottom: 0;

            .SecondCardTitle {
                display: block;
                width: 100%;
                padding: 20px;

                .LeftDiv {
                    margin-right: 10px;
                    text-align: start;
                    margin-top: 40px;
                    margin-bottom: 25px;
                    width: 100%;

                    .parkingBtn {
                        width: auto;

                        .PackageParking {
                            display: none;
                        }
                    }
                }
            }
        }

        .SecondCardBuy.HideOnWeb {
            display: block;
            padding: 0;
        }
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 768px) and (max-width:992px) {
    .SecondCardSection {
        button.Price {
            font-size: 15px;
        }

        .LeftDiv {
            width: auto;
        }
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 992px) and (max-width:1200) {}

/* Large devices (laptops/desktops, 992px and up) */
@media (min-width: 1200px) and (max-width:1400) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media (min-width: 1400px) {}