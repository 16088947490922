.companySec {
  background-color: #fff;
  width: -webkit-fill-available;
  display: flex;

  .companys {
    padding: 20px 15px;
    display: flex;
    cursor: pointer;
    width: -webkit-fill-available;

    img.companyIcon {
      width: -webkit-fill-available;
      filter: grayscale(100%);
      -webkit-filter: grayscale(100%);
      padding: 10px;
      height: 100px;
    }

    img.companyIcon:hover {
      filter: grayscale(0%);
    }
  }
}

/* Extra small devices (phones, 600px and down) */
@media (max-width: 768px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 768px) and (max-width: 992px) {
  .companySec {
    .companys {
      padding: 15px 5px;
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 992px) and (max-width: 1200px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media (min-width: 1200px) and (max-width: 1400px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media (min-width: 1400px) {
}
