.EventChildModal {
  .EventChildModalBody {
    margin-top: 20px;
    text-align: center;

    .ModalPackageIcon {
      width: 120px;
      height: 120px;
    }

    .ModalHeadingEvent {
      font-family: "Roboto Condensed", sans-serif;
      margin-top: 20px;
    }
  }

  .EventChildModalBtn {
    text-align: center;
    margin: 20px;

    button {
      border-radius: 0;
      height: 44px;
      width: 160px;
      background-color: #4cb2dc;
      font-size: 18px;
      text-transform: uppercase;
    }

    button:hover {
      background-color: #313131;
      border-color: #313131;
    }
  }
}

.discription {
  p {
    img {
      width: 100%;
    }
  }
}
