.JobChildSec {
  display: flex;
  width: 60%;
  justify-content: center;
  margin: auto;
}

.JobSection {
  .container-fluid {
    .JobBG {
      .JobHead {
        .JobParaDiv {
          h3.JobChildHeading {
            font-size: 74px;
          }
        }
      }
    }
  }

  .JobDetailSection {
    .container {
      .ChildSection {
        padding: 0px 100px;
      }

      .PositionSec {
        .JobChildCategory {
          font-size: 14px;
          color: #000;
        }

        .JobChildHead {
          font-size: 30px;
          color: #000;
          word-break: break-word;
        }

        .JobChildPara {
          font-size: 16px;
          color: #575353;
          margin-bottom: 30px;
          display: flex;
          p {
            margin: 0 3px;
          }
          b {
            color: #000;
          }
        }

        .JobChildForm {
          .QuesHeading {
            color: #000;
            margin-top: 50px;
            font-weight: 600;
          }
          .form-control {
            border-color: #d9d9d9;

            &::placeholder {
              color: #000 !important;
              font-size: 16px;
            }
          }

          .form-control:focus {
            border-color: #d9d9d9;
          }

          label {
            font-family: 'Lato';
            font-weight: 400;
            font-size: 16px;
            color: #000000;
          }

          .SubmitBtnForm {
            margin: 25px 0px;
          }

          input#file {
            display: none;
          }

          .attachDiv {
            width: 100%;
            display: flex;
            justify-content: space-between;
            border: 1px solid #d9d9d9;
            padding: 0px 10px;

            p {
              color: #000;
            }
          }
        }
      }
    }
  }
}
.errorMsgs {
  color: red;
  margin-top: 4px;
}

/* Extra small devices (phones, 600px and down) */
@media (max-width: 768px) {
  .JobSection {
    .container-fluid {
      .JobBG {
        padding-top: 55px;

        .JobParaDiv {
          h3.JobChildHeading {
            font-size: 45px !important;
          }
        }
      }
    }

    .JobDetailSection {
      .container {
        .JobChildSec {
          width: 90%;

          .ChildSection {
            padding: 0;
          }
        }
      }
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 768px) and (max-width: 992px) {
  .JobSection {
    .container-fluid {
      .JobBG {
        padding-top: 100px;
        padding-bottom: 150px;
      }
    }

    .JobDetailSection {
      .container {
        .JobChildSec {
          width: 90%;

          .ChildSection {
            padding: 0 30px;
          }
        }
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .JobSection {
    .container-fluid {
      .JobBG {
        padding-top: 100px;
        padding-bottom: 150px;
      }
    }

    .container {
      .JobChildSec {
        width: 90%;

        .ChildSection {
          padding: 0 30px;
        }
      }
    }
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media (min-width: 768px) and (max-width: 1200px) {
  .JobDetailSection {
    .container {
      .JobChildSec {
        .PositionSec {
          width: 100%;
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .JobDetailSection {
    .container {
      .JobChildSec {
        .PositionSec {
          width: 90%;
        }
      }
    }
  }
}
