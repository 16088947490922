.VenueShowsCard {
  margin-top: 30px;

  .VenueCardSection {
    .VenueCard {
      .card {
        border-radius: 0;
        min-height: 297px;

        .CardImage {
          margin: auto;
          padding: 22px 26px;

          img {
            width: 190px;
            height: 90px;
            border-radius: 0;
          }
        }

        .card-body {
          min-height: 130px;
        }

        hr {
          margin: 0;
        }

        .VenueCardDetail {
          text-align: center;
          margin-top: 20px;
          margin-bottom: 10px;

          h5.card-title {
            font-weight: 700;
            font-family: "Roboto Condensed";
            text-align: center;
            text-transform: uppercase;
            font-size: 22px;
            min-height: 53px;
            max-height: 82px;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2 !important;
          }

          p.card-text {
            font-size: 12px;
            color: #4a4a4a;
            overflow: hidden;
            max-height: 35px;
            min-height: 35px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2 !important;
          }
        }

        .card-footer {
          padding: 0;

          .MoreInfoBtn {
            font-weight: 700;
            border: 0;
            border-radius: 0;
            width: 100%;
            padding: 10px;

            &:hover,
            :focus {
              background-color: #289dcd;
            }
          }
        }
      }
    }
  }
}
.NoCardDetails {
  font-size: 20px;
}
.EventChild-Details-Sec img,
.EventDirectionHeading img {
  width: 100% !important;
}

.venueCardList {
  margin-top: 20px;
  margin-bottom: 60px;
  .carousel-indicators {
    align-items: center;
    margin-bottom: 0;
  }
}

.VenueCardUpcomingShows {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .GridIcon {
    .GridIconSec {
      width: max-content;
    }
  }
}

// Venue Card Details CSS
.VenueDetailSection {
  .VenueContent p img {
    width: 100% !important;
    padding: 0 30px;
  }

  .modal-header {
    border-bottom: 0;

    button.btn-close:focus {
      box-shadow: none;
    }
  }

  .modal-body {
    padding-top: 0;

    img {
      width: 100%;
    }
  }

  .ModalButton:active,
  :focus {
    border: 0;
  }

  .ModalButton {
    background-color: transparent;
    width: 100%;
    border: none;
  }

  p.bold {
    font-weight: 600;
  }

  h3 {
    font-weight: bold;
  }

  .VenueDetailHead {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button.BackToVenueBtn {
      padding: 7px 30px;
      font-size: 18px;
      font-weight: 600;
      border: 0;
    }
  }

  .VenueCompanyDetails-Section {
    p {
      color: #4a4a4a;
      font-size: 14px;
      font-family: Lato, "Helvetica Neue", Helvetica, Arial, sans-serif;
    }

    li {
      color: #4a4a4a;
      font-size: 12px;
    }

    p.bold {
      font-weight: 600;
      font-size: 12px;
    }

    .VenueCompanyImg {
      text-align: center;
      position: relative;

      img {
        max-width: 100%;
        width: 100%;
        margin-bottom: 30px;
        padding: 10px 60px;
        object-fit: cover;
      }
    }

    .VenueDirectionHeading {
      margin-bottom: 40px;
      img {
        object-fit: contain;
        max-width: 100%;
      }
      iframe {
        max-width: 100%;
      }
    }
    .VenueParkingHead .ParkingHeading {
      img {
        max-width: 100%;
        object-fit: contain;
      }
      iframe {
        max-width: 100%;
      }
    }
  }

  .BoxOfficeSection {
    img {
      object-fit: contain;
      max-width: 100%;
    }
    iframe {
      max-width: 100%;
    }
    .PaymentHead p {
      display: inline-block;
      padding: 3px;
    }

    p {
      font-size: 12px;
      font-family: Lato, "Helvetica Neue", Helvetica, Arial, sans-serif;
      color: #4a4a4a;
    }

    .SeatingChartSec {
      img {
        width: 90px;
        object-fit: cover;
        height: 90px;
        margin-bottom: 10px;
        margin-right: 8px;
        cursor: pointer;
      }
    }
  }

  .VenueCardUpcomingSection {
    margin-bottom: 4rem;
  }
}

.NoData {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 30%;
  }
}

/* Extra small devices (phones, 600px and down) */
@media (max-width: 768px) {
  .VenueDetailHead {
    display: block !important;
  }

  .VenueShowsCard {
    margin-bottom: 30px;

    .VenueCardSection {
      .card {
        height: 319px !important;

        .CardImage {
          padding: 30px 26px;
        }

        h5.card-title {
          margin-bottom: 30px;
        }

        .VenueCardDetail {
          margin-top: 0 !important;
        }
      }
    }
  }
  .VenueParkingHead .ParkingHeading h1,
  .VenueDirectionHeading h1,
  .BoxOfficeSection h1,
  .VenueContent h1 {
    font-size: 24px;
    word-wrap: break-word;
  }
  .VenueParkingHead .ParkingHeading h2,
  .VenueDirectionHeading h2,
  .BoxOfficeSection h2,
  .VenueContent h2 {
    font-size: 20px;
    word-wrap: break-word;
  }
  .SeatingChartSec {
    .col-xs-4 {
      width: 33.33%;
    }
  }

  .NoData {
    img {
      width: 85%;
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 768px) and (max-width: 992px) {
  .CardImage {
    padding: 30px 10px !important;
  }
  .VenueParkingHead .ParkingHeading h1,
  .VenueDirectionHeading h1,
  .BoxOfficeSection h1,
  .VenueContent h1 {
    font-size: 24px;
    word-wrap: break-word;
  }
  .VenueParkingHead .ParkingHeading h2,
  .VenueDirectionHeading h2,
  .BoxOfficeSection h2,
  .VenueContent h2 {
    font-size: 20px;
    word-wrap: break-word;
  }
  .VenueShowsCard
    .VenueCardSection
    .VenueCard
    .card
    .VenueCardDetail
    p.card-text {
    max-height: 18px;
  }

  h5.card-title {
    min-height: 80px !important;
  }

  .VenueDetailSection .BoxOfficeSection .SeatingChartSec img {
    width: 55px;
    object-fit: cover;
    height: 55px;
  }

  .NoData {
    margin-bottom: 8rem;

    img {
      width: 100%;
    }
  }
}

@media (min-width: 992px) and (max-width: 1400px) {
  h5.card-title {
    min-height: 80px !important;
  }
  .VenueParkingHead .ParkingHeading,
  .VenueDirectionHeading,
  .BoxOfficeSection {
    h1 {
      font-size: 30px;
    }
  }
  .VenueParkingHead .ParkingHeading h1,
  .VenueDirectionHeading h1,
  .BoxOfficeSection h1,
  .VenueContent h1 {
    font-size: 24px;
    word-wrap: break-word;
  }
  .VenueParkingHead .ParkingHeading h2,
  .VenueDirectionHeading h2,
  .BoxOfficeSection h2,
  .VenueContent h2 {
    font-size: 20px;
    word-wrap: break-word;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 992px) and (max-width: 1200px) {
  .CardImage {
    padding: 30px 10px !important;
  }

  .VenueDetailSection .BoxOfficeSection .SeatingChartSec img {
    width: 75px;
    height: 75px;
  }

  .NoData {
    margin-bottom: 5rem;

    img {
      width: 91%;
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media (min-width: 1200px) and (max-width: 1400px) {
  .NoData {
    margin-bottom: 2rem;

    img {
      width: 35%;
    }
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media (min-width: 1400px) {
  .NoData {
    img {
      width: 35%;
    }
  }
}
